import Link from "next/link";
import styled from "styled-components";
import { width } from "../utils";

const SiteLogo = styled.div`
  position: absolute;
  left: 30px;
  top: 15px;
  cursor: pointer;

  img {
    height: 60px;
  }

  ${width.small`
  display: block;
    top: -7px;
    left: 0px;
    img {
      height: 34px;
      width: auto;
    }
  `}
`;

class PublicNavBrand extends React.Component {
  render() {
    return (
      <Link href="/">
        <a>
          <SiteLogo>
            <img src="/static/buddhacloud-logo.svg" alt="Buddhacloud Logo" />
          </SiteLogo>
        </a>
      </Link>
    );
  }
}

export default PublicNavBrand;
