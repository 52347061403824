import Link from "next/link";
import styled from "styled-components";
import { Mutation } from "react-apollo";
import { CURRENT_USER_QUERY } from "../utils/User";
import gql from "graphql-tag";
import Error from "../ErrorMessage";
import FormStyles from "../styles/FormStyles";
import { width } from "../utils";
import RequiredIcon from "../utils/RequiredIcon";
import { PrimaryButton } from "../styles/ButtonStyles";

const SIGNUP_MUTATION = gql`
  mutation SIGNUP_MUTATION(
    $email: String!
    $firstName: String!
    $lastName: String!
    $password: String!
  ) {
    signup(
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
    ) {
      user {
        id
        firstName
        lastName
        email
      }
      token
    }
  }
`;

const FormWidth = styled.div`
  width: 480px;
  margin: 0 auto;

  ${width.med`
    width: 80%;
  `}
`;

class SignUpForm extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  };

  saveToState = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <Mutation
        mutation={SIGNUP_MUTATION}
        variables={this.state}
        refetchQueries={[{ query: CURRENT_USER_QUERY }]}
      >
        {(signup, { error, loading }) => {
          return (
            <FormWidth>
              <FormStyles
                method="post"
                onSubmit={async (e) => {
                  e.preventDefault();
                  try {
                    const res = await signup();
                    document.cookie = `token=${res.data.signup.token}`;
                    localStorage.setItem("token", res.data.signup.token);
                    window.location.href = "/library";
                  } catch (err) {
                    console.log(err);
                  }
                }}
              >
                <fieldset disabled={loading} aria-busy={loading}>
                  <h1>Sign Up</h1>
                  <Error error={error} />
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="first-name">
                        First Name <RequiredIcon />
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        value={this.state.firstName}
                        onChange={this.saveToState}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="last-name">
                        Last Name <RequiredIcon />
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        value={this.state.lastName}
                        onChange={this.saveToState}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">
                      Email <RequiredIcon />
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={this.state.email}
                      onChange={this.saveToState}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">
                      Password <RequiredIcon />
                    </label>
                    <input
                      type="password"
                      name="password"
                      value={this.state.password}
                      onChange={this.saveToState}
                      required
                    />
                  </div>
                  <PrimaryButton type="submit">Sign Up</PrimaryButton>
                  <div className="button-container">
                    <Link href="/login">
                      <div className="account-link">
                        <a>Already have an account?</a>
                      </div>
                    </Link>
                    <Link href="/password">
                      <div className="account-link">
                        <a>Forgot Password?</a>
                      </div>
                    </Link>
                  </div>
                </fieldset>
              </FormStyles>
            </FormWidth>
          );
        }}
      </Mutation>
    );
  }
}

export default SignUpForm;
