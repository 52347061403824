import Link from "next/link";
import styled, { ThemeProvider, injectGlobal } from "styled-components";
import User from "../utils/User";
import { theme, width } from "../utils";
import { withRouter } from "next/router";
import NavAvatar from "./NavAvatar";

const NavStyles = styled.ul`
  width: 100%;

  .nav-item-container {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 0px;

    position: absolute;
    top: 0px;
    right: 30px;
    color: ${theme.darkColor};
    font-family: ${theme.navItemFont};
    font-size: 1.6rem;
    margin-top: 32px;
    text-align: right;

    ${width.large`

    `}

    ${width.small`
      display: none;
    `}



    a {
      margin: 0 15px;
    }

    .cart-container {
      display: inline-block;

      ${width.med`
        a {
          margin: 0;
        }
      `}

      ${width.small`
        position: absolute;
        top: -10px;
        right: 40px;
        width: 90px;
      `}
    }
  }
`;

const NavItem = styled.a`
  margin: 0 15px;
  color: ${props =>
    props.active ? "#EAA976 !important" : "#EAA976 !important"};
  font-family: ${props =>
    props.active
      ? theme.bodyFontBold + "!important"
      : theme.bodyFontBold + "!important"};
  cursor: pointer;
  font-size: 1.8rem;
`;

class PublicNavItems extends React.Component {
  render() {
    const { router } = this.props;
    return (
      <NavStyles>
        <div className="nav-item-container">
          <div>
            <Link href="/">
              <NavItem active={router.pathname === "/"}>Sign Up</NavItem>
            </Link>
            <Link href="/login">
              <NavItem active={router.pathname === "/login"}>Log In</NavItem>
            </Link>
          </div>
        </div>
      </NavStyles>
    );
  }
}

export default withRouter(PublicNavItems);
