import PublicNavigationBar from '../components/nav/PublicNavigationBar';
import SignUp from '../components/access/SignUp';
import { logPageView, initGA } from '../components/utils/analytics';

class Home extends React.Component {
  componentDidMount = () => {
    initGA();
    logPageView();
  };

  render() {
    return (
      <div>
        <PublicNavigationBar />
        <SignUp />
      </div>
    );
  }
}

export default Home;
